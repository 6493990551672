<!-- Finder Contawin Descuadres  -->

<template>
    <div class="ctw_descuadres" v-if="loaded"> 

      <div style="display:flex;justify-content:center">
        <v-sheet v-if="!viewMtoOnly" :elevation="4" style="width:500px">

          <!-- Cabecera -->
          <div class="cabecera" v-if="!viewMtoOnly" >     
            <baseHeader
              :cfg="$cfe.headers.finder"
              :titulo='$store.state[storeName].titulo'
              @onEvent="eventHeader"> 
            </baseHeader>
          </div>
            

          <!-- Contenido -->
          <div class="contenedor"> 

            <!-- Filtro de Búsqueda -->
            <div class="columna">             
              <div style="display:flex">
                <v-select  
                    style="flex: 0 0 25%"              
                    v-bind="$select"
                    v-model="ct.tip[2]"     
                    :label="ct.tip[1]"                
                    :items="tipos"
                    item-value="d"
                    item-text="n">            
                </v-select>

                <v-select  
                    style="flex: 0 0 25%"              
                    v-bind="$select"
                    v-model="ct.eje[2]"     
                    :label="ct.eje[1]"                  
                    :items="ejercicios">            
                </v-select>

                <v-select  
                    style="flex: 0 0 35%"              
                    v-bind="$select"
                    v-model="ct.mes[2]"     
                    :label="ct.mes[1]"                                                    
                    :items="meses"
                    item-value="d"
                    item-text="n">            
                </v-select>

                 <v-btn v-bind="$cfe.default.btra.std" class="ml-2" title="Buscar" @click="eventFiltro('buscar')">
                    <v-icon>{{'mdi-filter'}}</v-icon>
                </v-btn>
              </div>
            </div>
                               
              <hr class="mt-1">
              
              <!-- Grid -->                        
              <baseGridMD
                :store="storeName"
                :headers="headerGrid"
                :items-per-page="-1"           
                dense
                height="350px"
                hide-default-footer 
                @onEvent="execAccion">          
              </baseGridMD>

          </div>
        </v-sheet> 
      </div>
  </div>
</template>




<script>

  import { mixF } from "@/mixins/mixF.js";  
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
    
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD }, 
    props: {},

    data() {
      return {
        stIni: {
          api: "",
          titulo:"ContaWin - Descuadres", 
          name:"descuadres",
          mView:'ctw_descuadres',
          pView:[]
        },

        fh: new Date(),
        
        // array de Tipo de Descuadre    
        tipos: [
            { d: '1', n: 'Servicios' },
            { d: '2', n: 'Asientos' }
        ],

        // array de Años
        ejercicios: [],

        // array de Meses
        meses: [
            {d: '0', n: 'Mes' },
            {d: '1', n: 'Enero' },
            {d: '2', n: 'Febrero' },
            {d: '3', n: 'Marzo' },
            {d: '4', n: 'Abril' },
            {d: '5', n: 'Mayo' },
            {d: '6', n: 'Junio' },
            {d: '7', n: 'Julio' },
            {d: '8', n: 'Agosto' },
            {d: '9', n: 'Septiembre' },
            {d: '10', n: 'Octubre' },
            {d: '11', n: 'Noviembre' },
            {d: '12', n: 'Diciembre' }
        ],

      }    
    },



    methods: {
      iniDataParticular() {        
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:[],
          fields:[        
            { text: "Ref",   value: "doc", width: "20%" },            
            { text: "Fecha", value: "f", width: "20%"},
            { text: "Debe",  value: "d", width: "20%", filtro:'num' },
            { text: "Haber", value: "h", width: "20%", filtro:'num' },
            { text: "Saldo", value: "s", width: "20%", filtro:'num' }
          ]}
        ];

        this.headerGrid= this.headerSet();

        //años (hasta 2017)
        for (let y = this.fh.getFullYear().toString(); y>=2017; y--) {
          this.ejercicios.push({value:y, text:y});
        }                

        // asigno año actual       
        this.$set(this.ct.eje, 2, this.fh.getFullYear().toString());
      },

     
     // APIS particulares
     // pongo a null el sch para que no cargue del servidor el schema
      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.get= ["", 'descuadres', {}, "contawin" ];
      },


    },


    computed: {
      sch() {
        
        return {
          tip:["tip", "Tipo", "1"],
          eje:["eje", "Ejercicio", ""],
          mes:["mes", "Mes", "0"]
        };

      }
    }

  };
</script>
